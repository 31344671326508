<template>
  <div>
    <b-form-group
      label="Yeniden Arama Tarihi"
      label-for="calldate"
    >
      <b-form-datepicker
        id="calldate"
        v-model="dataItem.calldate"
        v-bind="{labelNoDateSelected: 'Yeniden Arama Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars

export default {
  name: 'CrcLinesCallDateCard',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['crcLines/dataItem']
    },
  },

}
</script>
<style scoped></style>
