<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        :md="itemData.id_com_crc_status === '2' ? 6: 12"
      >
        <crc-lines-status-card />
      </b-col>
      <b-col
        v-if="itemData.id_com_crc_status === '2'"
        cols="12"
        md="6"
      >
        <crc-lines-call-date-card />
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <crc-lines-content-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <crc-lines-interview-card />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <crc-lines-alert-card />
      </b-col>
      <template v-if="itemData.interview">
        <b-col
          cols="12"
          md="6"
        >
          <crc-lines-interview-subjects-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <crc-lines-interview-status-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Görüşme Tarihi"
            label-for="idate"
          >
            <b-form-datepicker
              id="idate"
              v-model="itemData.idate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Görüşme Saati"
            label-for="itime"
          >
            <b-form-timepicker
              id="itime"
              v-model="itemData.itime"
              :locale="locale"
              v-bind="labels[locale] || {}"
              @context="onContext"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <crc-lines-meets />
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormDatepicker, BFormTimepicker,
} from 'bootstrap-vue'
import CrcLinesStatusCard from '@/views/Crm/Crc/Elements/CrcStatusCard.vue'
import CrcLinesCallDateCard from '@/views/Crm/Crc/Elements/CrcLinesCallDateCard.vue'
import CrcLinesContentCard from '@/views/Crm/Crc/Elements/CrcLinesTextComponent.vue'
import CrcLinesInterviewCard from '@/views/Crm/Crc/Elements/CrcLinesInterview.vue'
import CrcLinesAlertCard from '@/views/Crm/Crc/Elements/CrcLinesAlert.vue'
import CrcLinesInterviewSubjectsCard from '@/views/Crm/Crc/Elements/InterviewSubject.vue'
import CrcLinesInterviewStatusCard from '@/views/Crm/Crc/Elements/InterviewStatus.vue'
import CrcLinesMeets from '@/views/Crm/Crc/Elements/CrcLinesMeet.vue'

export default {
  name: 'CrcLinesItemForm',
  components: {
    CrcLinesMeets,
    CrcLinesInterviewStatusCard,
    CrcLinesInterviewSubjectsCard,
    CrcLinesAlertCard,
    CrcLinesInterviewCard,
    CrcLinesContentCard,
    CrcLinesCallDateCard,
    CrcLinesStatusCard,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
  },
  data() {
    return {
      locale: 'tr',
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'tr', text: 'Türkçe (tr)' },
      ],
      labels: {
        tr: {
          labelHours: 'Saat',
          labelMinutes: 'Dakika',
          labelSeconds: 'Saniye',
          labelIncrement: 'Arttır',
          labelDecrement: 'Azalt',
          labelSelected: 'Seçildi',
          labelNoTimeSelected: 'Saat Seçiniz',
          labelCloseButton: 'Seç',
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['crcLines/dataItem']
    },
  },
  methods: {
    onContext(ctx) {
      this.context = ctx
    },
  },
}
</script>
