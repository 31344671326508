<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <crc-filter :call-back="searchData" />
      </b-card-body>
      <template v-if="dataLoading">
        <loading />
      </template>
      <template v-else>
        <b-alert
          v-if="dataList.length < 1"
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Eşleşen veri bulunmuyor. 👏
            <div class="mt-2">
              <b-button
                variant="primary"
                :to="$route.path + '/add'"
                size="sm"
              >
                <FeatherIcon icon="PlusIcon" />
                Oluştur
              </b-button>
            </div>
          </div>
        </b-alert>
        <template v-else>
          <b-table

            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(customer)="data">
              {{ data.item.customer }}
              <b-badge variant="light-secondary">
                <feather-icon icon="PhoneIcon" />
                {{ data.item.phone }}
              </b-badge>
              <div v-if="data.item.company_name">
                <small class="text-primary">{{ data.item.company_name }}</small>
              </div>
              <div class="mt-1">
                <b-badge variant="light-primary">
                  <feather-icon icon="PhoneCallIcon" />
                  {{ getCrcTypes(data.item.id_com_crc_type) }}
                </b-badge>
              </div>
              <div style="margin-top: 1%">
                <b-badge
                  variant="light-info"
                >
                  <feather-icon icon="MapPinIcon" />
                  {{ data.item.location }}
                </b-badge>
                <b-badge
                  class="ml-1"
                  variant="light-warning"
                >
                  <feather-icon icon="CodesandboxIcon" />
                  {{ data.item.brand }}
                </b-badge>
              </div>
            </template>
            <template #cell(crc_status)="data">
              <b-badge :variant="data.item.variant">
                <feather-icon :icon="data.item.icon" />
                {{ data.item.crc_status }}
              </b-badge>
              <div v-if="data.item.id_com_crc_status === '2' && data.item.calldate">
                <small><b class="text-info">{{ moment(data.item.calldate).format('DD.MM.YYYY') }} </b> tarihinde arama bekliyor</small>
              </div>
            </template>
            <template #cell(username)="data">
              <div class="mt-1">
                <b><feather-icon icon="UserIcon" /> {{ data.item.username }}</b>
                <hr>
                <div>
                  <small class="text-muted"><feather-icon icon="SaveIcon" /> {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
                  <div v-if="data.item.modified">
                    <small class="text-warning"><feather-icon icon="EditIcon" /> {{ moment(data.item.modified).format('DD.MM.YYYY HH:mm:ss') }}</small>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(control)="data">
              <div class="text-right">
                <b-dropdown
                  variant="link"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MenuIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="$route.path + '/view/' + data.item.id">
                    Detaylar
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="data.item.status !== '1'"
                    @click="showModal(data.item.id)"
                  >
                    Güncelle
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCounts"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
      </template>
    </b-card>
    <b-modal
      v-model="modal.status"
      title="Yeni Durum Oluştur"
      centered
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <validation-observer ref="simpleRules">
        <crc-lines-item-form />
      </validation-observer>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="modal.status=false"
        >
          İptal
        </b-button>
        <b-button
          variant="success"
          :disabled="modal.saving"
          @click="saveModalData"
        >
          <feather-icon
            v-if="!modal.saving"
            icon="SaveIcon"
          />
          <b-spinner
            v-if="modal.saving"
            small
          />
          Kaydet
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BAlert, BSpinner, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, localize } from 'vee-validate'
import Loading from '@/layouts/components/Loading.vue'
import CrcLinesItemForm from '@/views/Crm/Crc/Components/ItemForm.vue'
import CrcFilter from '@/views/Crm/Crc/Components/CrcFilter.vue'

const tableName = 'com_crc'
export default {
  name: 'Index',
  components: {
    CrcFilter,
    CrcLinesItemForm,
    Loading,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BAlert,
    ValidationObserver,
    BSpinner,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'customer',
          label: 'Müşteri / Firma',
        },
        {
          key: 'crc_status',
          label: 'Durum',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'username',
          label: 'Danışman',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_customer.phone AS phone',
          `${tableName}.id_com_crc_type AS id_com_crc_type`,
          `${tableName}.id_com_crc_status AS id_com_crc_status`,
          `${tableName}.calldate AS calldate`,
          'com_crc_status.title AS crc_status',
          'com_crc_status.status AS status',
          'com_crc_status.variant AS variant',
          'com_crc_status.icon AS icon',
          'com_location.title AS location',
          'com_user.name AS username',
          'com_brand.name AS brand',
          `${tableName}.created AS created`,
          `${tableName}.modified AS modified`,
        ],
        where: {},
        where_in: {},
        or_like: {},
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['crc/dataList']
    },
    dataCounts() {
      return this.$store.getters['crc/dataCounts']
    },
    saveData() {
      return this.$store.getters['crc/dataSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['crc/dataLoading']
    },
    modal() {
      return this.$store.getters['crcLines/modalData']
    },
    modalData() {
      return this.$store.getters['crcLines/dataItem']
    },
    filterData() {
      return this.$store.getters['crc/filterData']
    },
    crcTypes() {
      return this.$store.getters['crc/crcTypes']
    },

  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.searchData()
    localize('tr')
  },
  methods: {
    searchData() {
      const where = {}
      const whereIn = []
      if (this.filterData.keywords) {
        this.dataQuery.or_like = {
          'com_customer.company_name': this.filterData.keywords,
          'com_customer.name': this.filterData.keywords,
          'com_customer.phone': this.filterData.keywords,
          'com_cars.license_plate': this.filterData.keywords,
          'com_cars.chassis': this.filterData.keywords,
          'com_interview.interview_number': this.filterData.keywords,
        }
      } else delete this.dataQuery.or_like

      if (this.filterData.id_com_brand.length) {
        whereIn.push([
          'com_crc.id_com_brand', this.filterData.id_com_brand,
        ])
      }
      if (this.filterData.id_com_location.length) {
        whereIn.push([
          'com_crc.id_com_location', this.filterData.id_com_location,
        ])
      }
      if (this.filterData.id_com_crc_status.length) {
        whereIn.push([
          'com_crc.id_com_crc_status', this.filterData.id_com_crc_status,
        ])
      }
      if (whereIn) {
        this.dataQuery.where_in = whereIn
      } else delete this.dataQuery.where_in

      if (this.filterData.id_com_crc_type) {
        where['com_crc.id_com_crc_type'] = this.filterData.id_com_crc_type
      }
      if (this.filterData.id_com_user) {
        where['com_crc.id_com_user'] = this.filterData.id_com_user
      }
      if (where) {
        this.dataQuery.where = where
      } else delete this.dataQuery.where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('crc/getDataList', this.dataQuery)
    },
    showModal(id) {
      this.modal.status = true
      this.modal.id = id
      this.$store.commit('crcLines/RESET_DATA_ITEM')
    },
    saveModalData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.modal.saving = true
          this.modalData.id_com_crc = this.modal.id
          this.$store.dispatch('crcLines/saveData', this.modalData)
            .then(response => {
              if (response.status) {
                this.modal.status = false
                this.getDataList()
              }
              this.modal.saving = false
            })
        }
      })
    },
    getCrcTypes(id) {
      const found = this.crcTypes.find(item => item.id == id)
      return found ? found.title : null
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
