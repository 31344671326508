<template>
  <div>
    <b-form-group
      label="Görüşme Notu Oluştur"
      label-for="interview"
    >
      <b-form-checkbox
        id="interview"
        v-model="itemData.interview"
        value="1"
        switch
      >
        {{ itemData.interview === '1'? 'Aktif' : 'Pasif' }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'CrcLinesInterviewCard',
  components: { BFormGroup, BFormCheckbox },
  computed: {
    itemData() {
      return this.$store.getters['crcLines/dataItem']
    },
  },
}
</script>
